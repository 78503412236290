<template>
  <el-container>
    <!-- 左侧 -->
    <el-aside width="200px">
      <el-menu
        :default-active="curactive"
        background-color="#303030"
        text-color="#fff"
        active-text-color="#ffd103"
        unique-opened
        router
      >
        <div v-for="obj in treelist" :key="obj.index">
          <!-- 可展开的菜单儿子 -->
          <el-submenu v-if="obj.children" :index="obj.index">
            <template slot="title">
              <i :class="obj.i"></i>
              <span>{{ obj.text }}</span>
            </template>

            <!-- 儿子，应该循环当前数据的obj.children数组 -->
            <el-menu-item
              v-for="child in obj.children"
              :key="child.index"
              :index="child.index"
              >{{ child.text }}</el-menu-item
            >
          </el-submenu>
          <!-- 无法展开直接点击的菜单儿子 -->
          <el-menu-item v-else :index="obj.index">
            <i :class="obj.i"></i>
            <span slot="title">{{ obj.text }}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </el-aside>

    <!-- 右侧 -->
    <el-container>
      <!-- 右-头 -->
      <el-header>
        <div style="width:100%" class="flex-c flex-between">
          <p class="colorfff">
            图片过大建议压缩一下<a
              class="colorfff"
              href="https://tinypng.com/"
              target="_back"
              >https://tinypng.com/</a
            >
          </p>
          <div class="flex-c">
            <h3>{{ myuser }}</h3>
            <!-- <img @click="clickHead" class="index-head-img ml10" :src="head" /> -->
            <div class="ml50 mr30 font14 colorfff pointer" @click="loginOut">
              退出登录
            </div>
          </div>
        </div>
      </el-header>
      <!-- 右-身 -->
      <el-main>
        <el-card>
          <router-view></router-view>
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// accountinfo
import { logout } from "@/apis/users";
// import { bus } from "@/utils/utils";

export default {
  data() {
    return {
      treelist: [
        {
          index: "1",
          i: "el-icon-picture-outline",
          text: "banner管理",
          children: [{ index: "/index/bannerlist", text: "banner列表" }],
        },

        // { index: "/index/orderman", i: "el-icon-bell", text: "案例管理" },
        // {
        //   index: "3",
        //   i: "el-icon-document-copy",
        //   text: "商品管理",
        //   children: [
        //     { index: "/index/itemlist", text: "商品列表" },
        //     { index: "/index/itemadd", text: "商品添加" },
        //     { index: "/index/itemtype", text: "商品分类" },
        //   ],
        // },
        // { index: "4", i: "el-icon-loading", text: "店铺管理" },
        {
          index: "6",
          i: "el-icon-document-copy",
          text: "新闻管理",
          children: [
            { index: "/index/newslist", text: "新闻列表" },
            { index: "/index/newsadd", text: "添加新闻" },
          ],
        },
        {
          index: "7",
          i: "el-icon-coordinate",
          text: "案例管理",
          children: [
            { index: "/index/projectlist", text: "案例列表" },
            { index: "/index/projectadd", text: "添加案例" },
          ],
        },
        {
          index: "2",
          i: "el-icon-star-off",
          text: "招聘消息",
          children: [{ index: "/index/joblist", text: "招聘列表" }],
        },
        {
          index: "5",
          i: "el-icon-user",
          text: "账号管理",
          children: [
            { index: "/index/userlist", text: "账号列表" },
            { index: "/index/useradd", text: "添加账号" },
          ],
        },

        // {
        //   index: "6",
        //   i: "el-icon-coordinate",
        //   text: "商品统计",
        //   children: [
        //     { index: "6-1", text: "商品统计" },
        //     { index: "6-2", text: "订单统计" },
        //   ],
        // },
      ],
      curactive: "/index/main", //当前激活的index
      myuser: "", //我的用户名
      head: require("@/assets/imgs/header.jpg"), //用户头像
    };
  },
  created() {
    //如果是普通管理员，删除多余的选项卡，没有此权限
    if (localStorage.role == "normal") {
      this.treelist.splice(3);
    }
    this.myuser = localStorage.acc;

    //判断用户是否在登录状态
    // checktoken({ token: localStorage.token }).then((res) => {
    //   if (res.data.code == 0) {
    //     //还在登录状态
    //     //回填用户名
    //     this.myuser = localStorage.acc;
    //     //*请求头像数据
    //     // accountinfo({ id: localStorage.id }).then((res) => {
    //     //   this.head = res.data.accountInfo.imgUrl;
    //     // });
    //   } else {
    //     this.myuser = "请登录";
    //   }
    // });

    // 完整URL部分    协议://ip:端口/文件路径/文件名?参数#hash
    // URL可以没有某个部分，但是他们的顺序固定，不能交换
    // 例: https://fxhh.jd.com/?linkIds=235154858,234182670,218785861,237130120,1677002,1529242,1848381,1953077,257276815,260807383#234182670
    // 例: https://pro.jd.com/mall/active/3ZYfZKGRAhbHzJySpRriJoGWo8v6/index.html?innerAnchor=10029466111344&focus=3

    //问题： 怎么获取url的hash值 答： location.hash
    this.curactive = location.hash.replace("#", ""); //找到当前页面的index，赋给curactive激活对应菜单

    //监听bus的传值
    // bus.$on("refreshHead", (imgurl) => {
    //   this.head = imgurl; //把传入的新头像回填到右上角
    // });
  },
  methods: {
    clickHead() {
      this.$router.push("/index/userinfo");
    },

    // *推出登录
    loginOut() {
      logout().then((res) => {
        if (res.data.code == 0) {
          //退出登录成功
          localStorage.removeItem("token");
          localStorage.removeItem("acc");
          localStorage.removeItem("id");
          localStorage.removeItem("role");
          this.$router.push("/");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/styles/themes.less");

.el-menu {
  border: 0;
}

.el-container {
  width: 100%;
  height: 100%;

  .el-aside {
    background-color: @basecolor2;
  }

  .el-header {
    background-color: @basecolor;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .index-head-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}
</style>
